<template>
  <div v-if="!loading" class="mr6-dk ml6-dk page-container">
    <div class="display-flex align-center">
      <bh-icon :icon="'arrow-left'" class="f2 text-violet mr2 pointer" @output="goBack()" />
      <h1 class="text-left pt0-5">Device Status</h1>
    </div>
    <bh-loader v-if="!device"/>
    <div class="card f1-5">
      <div v-if="device && device.device" class="details-header display-flex align-center ph1 flex-wrap flex-column-mb">
        <div class="display-flex justify-between scroll-x-auto w100p w100p-mb">
          <div class="text-left pl1 pr1 w100p-mb">
            <div class="color-blue pt1 text-no-wrap">Device ID</div>
            <div class="text-no-wrap pb1 pt1">
              <div class="status pa1 br1 align-self-center">
                {{ device.deviceId }}
              </div>
            </div>
          </div>
          <div class="text-center pl1 pr1 w100p-mb">
            <div class="color-blue pt1 text-no-wrap">Serial Number</div>
            <div class="text-no-wrap pb1 pt1">
              <div class="ml3-dk status pa1 br1 align-self-center">
                {{device.device.deviceSerialNumber}}
              </div>
            </div>
          </div>
          <div class="text-center pl1 pr1 w100p-mb">
            <div class="color-blue pt1 text-no-wrap">Device Location</div>
            <div class="text-no-wrap pb1 pt2" v-if="device.device">{{ device.device.deviceLocation }}</div>
          </div>
          <div class="text-center pl1 pr1 w100p-mb">
            <div class="color-blue pt1 text-no-wrap">Device Status </div>
            <div class="ml3-dk status pa1 br1 align-self-center" :class="getDeviceStatus === 'Needs Attention' ? 'needs-attention' : 'normal'" v-if="device.device">{{ device.device.connectionStatus }}</div>
          </div>
          <div class="text-center pl1 pr1 w100p-mb border-left-grey-dk">
            <div class="color-blue pt1 text-no-wrap">Session Start </div>
            <div class="text-no-wrap pb1 pt2">{{ device && getOrdinalDate(device.session_start) }}</div>
          </div>
        </div>
      </div>
      <div>
        <bh-vitals-chart
          :deviceId="deviceId"
          :primaryModel="primaryModel"
          :chartName="chartName"
          @areaClicked="onAreaClicked"
          @brushEnd="onBrushEnd"
          @onCustomDateSelect="onCustomDateSelect"
          @onResetVital="getVitalsInfo"/>
      </div>
    </div>
    <div v-if="device && vitals.length" class="card mt2 mb2">
      <div class="w100p pa1 display-flex border-bottom">
        <div class="text-left w80p-dk w100p">
          <h2 class="f2">Your Information is critical to us</h2>
          <h4 class="text-grey">It will help us to improve the accuracy of the system.</h4>
        </div>
        <div class="display-flex justify-between align-center w20p w100p-mb pointer f1-2 pr2">
          <span class="underline text-violet" @click="changeAlertIndex(0)">Recent</span>
          <span class="underline text-violet" @click="currentAlertIndex < (unSubmittedFeedbacks.length - 1) && changeAlertIndex(currentAlertIndex + 1)">{{ prev }} </span>
          <span class="underline text-violet" @click="currentAlertIndex && changeAlertIndex(currentAlertIndex - 1)">{{ next }}</span>
        </div>
      </div>
      <div class="display-flex justify-between flex-wrap-mb">
        <bh-loader v-if="loadingFeedback"/>
          <!-- <span class="f1-5 f1-4-mb text-violet mb1" v-if="isAlert">Please tell us, whether the alert is code or not?</span> -->
        <div class="display-flex w100p border-bottom">
          <div class="display-flex justify-between w25p h100p pa2">
            <div class="display-flex flex-column">
              <span class="f1-5 f1-4-mb text-violet mb1">Is this device condition in danger?</span>
              <bh-radio-button
                class="pointer f1-5 f1-4-mb w100p mb2"
                :iconClasses="`mr1 text-violet f2 pointer${!isNewFeedback ? ' disabled' : ''}`"
                label="Yes"
                :disabled="!isNewFeedback"
                :checked="feedback.code"
                :icon="feedback.code ? 'radio-checked' : 'radio-unchecked'"
                @output="updateValue(feedback, 'code', true)"
              />
              <bh-radio-button
                class="pointer f1-5 f1-4-mb w100p mb2"
                :iconClasses="`mr1 text-violet f2 pointer${!isNewFeedback ? ' disabled' : ''}`"
                label="No"
                :disabled="!isNewFeedback"
                :checked="!feedback.code"
                :icon="!feedback.code ? 'radio-checked' : 'radio-unchecked'"
                @output="updateValue(feedback, 'code', false)"
              />
              <bh-select
                class="br0-5 border-violet pointer pa0-2 mt2"
                placeholder="Select Reason"
                :options="getFeedbackReasonOptions"
                :value="feedback.code_severity"
                :disabled="!feedback.code || !isNewFeedback"
                @output="updateValue(feedback, 'code_severity', $event)"
                />
            </div>
          </div>
          <div class="w75p">
            <bh-textarea
              classes="w100p bg-light-blue border-none border-light-blue h100p pa2 f1-5"
              class="h100p"
              :value="feedback.notes"
              @output="updateValue(feedback, 'notes', $event)"
            />
          </div>
        </div>
      </div>
      <div class="display-flex justify-end align-center w100p pa2 pointer">
        <span class="underline text-violet f1-2 mr5">Remind me Later</span>
        <bh-button
          btnClass="display-flex text-white f1-5 bg-violet border-none font-axiroma-medium pa1"
          class="pl3 pr3 bg-violet border-none br0-5"
          @output="submitFeedbackDeviceDetail"
          >
          Submit
        </bh-button>
      </div>
    </div>

    <!-- To open Feedback Modal with Detailed View on click of tooltip -->
    <bh-modal-overlay :showModal="showModal" @modal-close="toggleModal" disableOutSideClick>
      <div class="card mt2 mb2 display-flex flex-column align-center w50">
        <div class="w100p pa1 display-flex border-bottom justify-between align-center">
          <div class="text-left w80p-dk w100p">
            <h2 class="f2">Your Information is critical to us</h2>
            <h4 class="text-grey">It will help us to improve the accuracy of the system.</h4>
          </div>
          <bh-icon icon="cross" class="pointer" @output="toggleModal"/>
        </div>
        <div class="display-flex justify-between flex-wrap-mb w100p">
          <bh-loader v-if="loadingFeedback"/>
          <div class="display-flex w100p border-bottom">
            <div class="display-flex justify-between w25p h100p pa1">
              <div class="display-flex flex-column">
                <span class="f1-2 f1-4-mb text-violet mb1">Is this device condition in danger?</span>
                <bh-radio-button
                  class="pointer f1-2 f1-4-mb w100p mb1"
                  :iconClasses="`mr1 text-violet f2 pointer${!isNewFeedback ? ' disabled' : ''}`"
                  label="Yes"
                  :disabled="!isNewFeedback"
                  :checked="feedback.code"
                  :icon="feedback.code ? 'radio-checked' : 'radio-unchecked'"
                  @output="updateValue(feedback, 'code', true)"
                />
                <bh-radio-button
                  class="pointer f1-2 f1-4-mb w100p mb1"
                  :iconClasses="`mr1 text-violet f2 pointer${!isNewFeedback ? ' disabled' : ''}`"
                  label="No"
                  :disabled="!isNewFeedback"
                  :checked="!feedback.code"
                  :icon="!feedback.code ? 'radio-checked' : 'radio-unchecked'"
                  @output="updateValue(feedback, 'code', false)"
                />
                <bh-select
                  class="br0-5 border-violet pointer pa0-2 mt2"
                  placeholder="Select Reason"
                  :options="getFeedbackReasonOptions"
                  :value="feedback.code_severity"
                  :disabled="!feedback.code || !isNewFeedback"
                  @output="updateValue(feedback, 'code_severity', $event)"
                  />
              </div>
            </div>
            <div class="w75p">
              <bh-textarea
                classes="w100p bg-light-blue border-none border-light-blue h100p pa2 f1-5"
                class="h100p"
                :value="feedback.notes"
                @output="updateValue(feedback, 'notes', $event)"
              />
            </div>
          </div>
        </div>
        <div class="display-flex justify-end align-center w100p pa1 pointer">
          <span class="underline text-viole t f1-2 mr5">Remind me Later</span>
          <bh-button
            btnClass="display-flex text-white f1-5 bg-violet border-none font-axiroma-medium pa1"
            class="pl3 pr3 bg-violet border-none br0-5"
            @output="submitFeedbackDeviceDetail"
            >
            Submit
          </bh-button>
        </div>
        <!-- To be used later -->
        <!-- <div class="display-flex justify-between align-center w50p w100p-mb pointer f1-2 pr2">
          <span class="underline text-violet" @click="changeAlertIndex(0)">Recent</span>
          <span class="underline text-violet" @click="currentAlertIndex < (unSubmittedFeedbacks.length - 1) && changeAlertIndex(currentAlertIndex + 1)">{{ prev }} </span>
          <span class="underline text-violet" @click="currentAlertIndex && changeAlertIndex(currentAlertIndex - 1)">{{ next }}</span>
        </div> -->
        <!-- <div class="ph1">
          <bh-vitals-chart
            minimize
            :primaryModel="primaryModel"
            :chartName="chartName"
            @areaClicked="onAreaClicked"
            @brushEnd="onBrushEnd"
            @onCustomDateSelect="onCustomDateSelect"
            @onResetVital="getVitalsInfo"/>
        </div> -->
      </div>
    </bh-modal-overlay>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import VitalCharts from '@/components/device/VitalCharts'
import moment from '@/utils/moment'
import { updateValue } from '@/utils'
import { notes } from '@/mixins'
import { getActiveEngines } from '@/services/performance'

export default {
  name: 'bh-detail',
  mixins: [notes],
  components: {
    'bh-vitals-chart': VitalCharts
  },
  data () {
    return {
      // chart options
      chartName: 'deviceDetail',
      // Feedback options
      isAlert: true,
      // New alert vars
      markpointKey: '',
      markpointVal: '',
      // have to use separate file
      details: [],
      currentAlertIndex: 0,
      showModal: false,
      deviceId: '',
      primaryModel: ''
    }
  },
  async mounted() {
    const engineData = await getActiveEngines({ where: { } })
    engineData.filter(data => {
      if (data.engineName === 'RE') {
        this.primaryModel = data.isPrimary ? 'Algo' : 'AI'
      }
    })
    document.querySelector('body').addEventListener('openSubmitFeedBack', this.toggleModal, false)
  },
  beforeDestroy() {
    document.querySelector('body').removeEventListener('openSubmitFeedBack', this.toggleModal)
  },
  computed: {
    ...mapGetters(['loading']),
    ...mapGetters('common/user', ['userId']),
    ...mapGetters('device', ['device', 'vitals', 'unSubmittedFeedbacks', 'customDateSet', 'vitalsLength']),
    machine() {
      return this.device?.machine ?? {}
    },
    getDeviceStatus() {
      return this.primaryModel === 'AI' ? this.device.status_ai : this.device.status_re
    }
  },
  async beforeMount() {
    await this.getVitalsInfo()
  },
  methods: {
    ...mapActions({ setToast: 'setSingleProperty' }),
    ...mapActions(['setLoader']),
    ...mapActions('device', ['setBrushCoordIndex', 'setSingleProperty']),
    updateValue,
    async getVitalsInfo() {
      const { id } = this.$route.params
      this.deviceId = id
      if (id) {
        this.setLoader(true)
        await this.fetchDeviceDetails({ id })
        this.setLoader(false)
        if (!this.vitalsLength) {
          this.setToast(['toast', { duration: 6000, show: true, message: 'No data available', color: '#F44336' }])
        }
      }
    },
    async submitFeedbackDeviceDetail() {
      this.showModal = false
      try {
        this.setLoader(true)
        const submitFeedback = await this.submitFeedback()
        if (submitFeedback) {
          this.resetCurrentAlertIndex()
          await this.getVitalsInfo()
          this.resetFeedback()
        }
      } catch (error) {
        console.log('Error while submitting feedback', error)
      }
      this.setLoader(false)
    },
    getAge(date) {
      return moment.calculateAge(date)
    },
    getOrdinalDate(date) {
      return moment.getOrdinalDate(date * 1000)
    },
    goBack() {
      this.setLoader(true)
      this.setSingleProperty(['customDateSet', false])
      this.$router.push({ name: 'Dashboard' })
    },
    changeAlertIndex(index) {
      this.currentAlertIndex = index
      this.setBrushCoordIndex(index)
    },
    resetCurrentAlertIndex() {
      this.currentAlertIndex = 0
    },
    async onCustomDateSelect(e) {
      if (!e.isInvalidDate) {
        const { id } = this.$route.params
        if (id) {
          this.setLoader(true)
          await this.fetchDeviceDetails({ id: id, startDate: e.startTime, endDate: e.endTime })
          this.setLoader(false)
        }
      }
    },
    toggleModal() {
      this.showModal = !this.showModal
    }
  }
}
</script>

<style scoped>
  .false-alert-checkbox {
    border-right: 1px solid #cccccc;
    width: auto;
    min-width: 20%;
    white-space: nowrap;
    justify-content: flex-end;
  }

  .accuracy-chart {
    height: 80%;
    height: calc(100% - 5rem);
  }

  .details-header {
    border-bottom: 1px solid #E0E0E0;
  }

  .card {
    border: 1px solid #E0E0E0;
    background: #FFFFFF;
    box-shadow: 0px 3px 16px #0A4B6D2E;
    border-radius: 0.8rem;
  }
</style>
